.dropdownSidebar {
  background-color: white;
  padding-inline-start: 22px;
  padding-inline-end: 32px;
  border-radius: 12px;
  height: auto;
  margin-inline-start: 10px;
  display: none;
  overflow: auto;
  overscroll-behavior: contain;
  width: max-content;
}

.dropdownSidebarVisible {
  display: block;
}
