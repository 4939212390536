@import '@/styles/breakpoints.module';

.usualNavItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--palette-gray-800);
  padding: 8px;
  padding-inline-end: 12px;
  border-radius: var(--palette-border-radius-s);
  font-weight: var(--font-weight-nav-item);

  @include desktop {
    &:hover {
      background-color: var(--palette-primary-75);
    }
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.iconSlot {
  margin-inline-end: 8px;
  min-width: 24px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: var(--palette-primary-700);
  }
}
