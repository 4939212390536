@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.ctaBlockContainer {
  padding: 16px 0;
  background-color: var(--palette-white);
  z-index: 2;
}

.shadow {
  box-shadow: 0 0 30px 0 rgba(29, 29, 29, 0.15);
}

.ctaAddonContainer {
  padding: 0 0 16px 0;
  background-color: var(--palette-white);
}

.sticky {
  position: sticky;
  bottom: 0;
}

.ctaButtons {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 20px;

  & > * {
    width: 100%;
  }
}

.helpCenterPlate.helpCenterPlate.helpCenterPlate {
  padding: 16px;
  background-color: var(--palette-gray-70);
  border-radius: var(--palette-border-radius-s);
  @include typography.bodyM_;

  a {
    @include typography.bodyM_;
  }
}

.storeButtonWrapper {
  margin-bottom: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.storeButton {
  width: 100%;
}
