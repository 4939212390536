@import '@/styles/breakpoints.module';

.banner {
  position: relative;
  background-color: var(--palette-gray-600);
  height: var(--height-app-banner);
  z-index: var(--zIndices-banner);

  @include desktop {
    background-color: var(--palette-gray-800);
  }

  &[class*='contexts_light'] {
    background-color: var(--palette-gray-70);
  }
}

.container {
  max-width: var(--container-max-width);
  padding: 8px 16px;
  margin-inline: auto;
  width: 100%;
  height: 100%;

  @include desktop {
    padding: 12px var(--container-padding);
  }
}

.title {
  color: var(--color-text-primary);
}

.closeButton {
  flex-shrink: 0;
  color: #969697;
  transition: opacity 100ms;

  &:hover {
    opacity: 0.8;
  }
}

.mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 100%;

  .content {
    flex: 1;
    text-align: start;
  }

  .title {
    font-weight: var(--palette-font-weight-semibold);
  }

  .closeButton {
    width: 16px;
    height: 16px;

    &.closeButtonLight {
      color: #0a0a0a;
    }
  }
}

.desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .icon {
    display: block;
    margin-inline-end: -5px;
  }

  .closeButton {
    width: 24px;
    height: 24px;
  }
}

.storeButtons {
  display: flex;
  gap: 12px;
  align-items: center;
}

.logoContainer {
  color: var(--color-text-primary);
  background-color: var(--palette-black);

  width: 36px;
  height: 36px;

  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11.85px 6.57px;

  svg,
  img {
    width: 100%;
    height: auto;
  }
}
