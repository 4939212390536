@import '@/styles/breakpoints.module';

.giftBanner {
  margin: 0 auto;
  display: none;
  padding: 12px;
  padding-inline-start: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--color-header-gift-banner-border);
  background: var(--color-header-gift-banner-bg);
  backdrop-filter: blur(3px);
  transition: var(--product-slide-hover-transition);

  &:hover {
    border: 1px solid var(--color-btn-filled-border);
    transform: scale(1.02);
  }

  & > p {
    color: var(--color-text-primary);
    margin-inline-end: 8px;
  }

  img {
    margin-top: -8px;
    margin-inline-end: 4px;
  }
}

.pageScrolledDown {
  @include desktop {
    display: flex;
  }
}
