@import '@/styles/breakpoints.module';

.secondRowNavItem {
  white-space: nowrap;
  margin-inline-end: 20px;
  --space-btw-dropdown-and-header: 12px;
  --height-header: 48px;
  min-width: fit-content;

  & > :first-child {
    padding: 0;
  }

  @include desktop {
    margin-inline-end: 16px;
  }
}
