.menu {
  --header-full-height: calc(var(--height-header) + var(--height-app-banner));
  position: fixed;
  display: none;
  overflow: auto;
  top: var(--header-full-height);
  background-color: var(--palette-white);
  height: calc(100% - var(--header-full-height));
  width: 100%;
  z-index: 1299;

  &.menuOpened {
    display: flex;
    flex-direction: column;
  }
}

.menuItemsContainer.menuItemsContainer.menuItemsContainer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
