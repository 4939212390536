.burgerMenuCollapseSectionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;

  .summary {
    margin-inline-end: 12px;

    .title {
      font-weight: var(--font-weight-nav-item);
      margin-bottom: 2px;
      color: var(--palette-gray-900);
    }

    .description {
      color: var(--palette-gray-500);
    }
  }

  .arrow {
    color: var(--palette-gray-400);
  }
}
