@import '@/styles/breakpoints.module';

.navItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  font-size: 14px;
  font-style: normal;
  font-weight: var(--font-weight-nav-item);
  line-height: 20px;
  padding: 8px 0;

  transition: all 80ms ease-in-out;
  white-space: nowrap;

  &:hover {
    color: var(--palette-gray-125);
  }

  &:before {
    content: '';
    position: absolute;
    --nav-item-height: 20px;
    --arrow-height: 12px;

    --remaining-bottom-space: (var(--height-header) - var(--nav-item-height)) / 2;
    --nav-item-dropdown-arrow-offset-top: calc(
      100% + (var(--remaining-bottom-space) + var(--space-btw-dropdown-and-header) - var(--arrow-height))
    );
    top: var(--nav-item-dropdown-arrow-offset-top);

    width: 26px;
    height: var(--arrow-height);
    background: url('arrow.svg') no-repeat center;
    z-index: 1;
    inset-inline-start: 50%;
    transform: translateX(-50%);
    display: none;
  }

  & > *:last-child {
    transition: var(--nav-item-arrow-transition);
    margin-inline-start: 6px;
  }
}

.navItemOpenedWrapper {
  .navItem {
    position: relative;
    color: var(--palette-gray-125);

    &.navItemActive {
      color: var(--palette-primary-400);
    }

    &.hasDropdown:before {
      display: block;
    }

    & > *:last-child {
      transform: var(--nav-item-arrow-transform);
    }
  }

  & > *:last-child {
    display: block;
  }
}

.navItemWrapper {
  position: relative;
}

.navItemActive {
  color: var(--palette-primary-400);

  &:hover {
    color: var(--palette-primary-400);
  }
}

.navItemSelected {
  padding: 8px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    bottom: -32px;
    background-color: var(--palette-gray-600);
    border-radius: 8px 8px 0 0;
    z-index: -1;
  }
}
