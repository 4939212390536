.dropdownSidebarItemsGroup {
  padding-top: 32px;
  padding-bottom: 32px;
}

.dropdownSidebarItem {
  display: none;
  height: 100%;
  flex-direction: column;
}

.dropdownSidebarItemVisible {
  display: flex;
}
