.navItemDropDownWrapper {
  --dropdown-item-width: 371px;
  --padding-dropdown: 16px;
  --dropdown-left-side-half-width: (var(--dropdown-item-width) + (var(--padding-dropdown) * 2)) / 2;

  inset-inline-start: calc(50% - var(--dropdown-left-side-half-width) + var(--viewport-overflow-shift, 0px));
  padding-top: calc(
    ((var(--height-header) - var(--nav-item-height)) / 2) + var(--space-btw-dropdown-and-header)
  );

  position: absolute;
  top: 100%;
  display: none;
}

.navItemDropDown {
  box-shadow: 0px 0px 30px 0px rgba(11, 11, 11, 0.4);
  padding: var(--padding-dropdown);
  border-radius: var(--palette-border-radius-m);
  background-color: var(--palette-gray-100);
  list-style-type: none;
  display: flex;
  position: relative;
  overflow: hidden;
  height: auto;
  & > li:not(:last-child) {
    margin-bottom: 12px;
  }
}

.fixedHeight {
  align-items: flex-start;
  height: calc(var(--dropdown-items-height) + (var(--padding-dropdown) * 2));
  & > *:last-child {
    height: 100%;
  }
}
