@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.ctaSectionFooterCaption.ctaSectionFooterCaption {
  text-align: center;
  color: var(--color-text-primary);

  @include typography.subheadingS_;

  padding: 24px 0;

  @include tablet {
    padding: 32px 0;
    @include typography.subheadingL_;
  }

  @include desktop {
    padding: 48px 0 60px;
  }
}
